import { differenceInMonths, differenceInYears } from 'date-fns'
import { TFunction } from 'i18next'
import {
  formatDateWithCustomSelect,
  formatDateWithKey,
  monthYearFormatDate
} from '~/core/utilities/format-date'
import { CertificatesType, EducationsType } from '../types'

export const checkCertificateFieldFormatDate = ({
  attributes
}: {
  type?: string
  attributes?: CertificatesType & {
    from?: {
      month?: string
      year?: string
    }
  }
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  return attributes?.issueMonth || attributes?.issueYear ? true : false
}

export const getCertificateFieldFormatDate = ({
  attributes = {} as any,
  isTemplate = false,
  dateFormatContent = '' as string
}) => {
  if (Object.keys(attributes).length === 0) return false
  const formatDate = `${attributes.issueYear}-${
    attributes.issueMonth >= 10
      ? attributes.issueMonth
      : `0${attributes.issueMonth}`
  }`
  if (attributes?.issueMonth) {
    if (attributes?.issueYear) {
      return isTemplate
        ? `${formatDateWithCustomSelect(
            new Date(formatDate),
            dateFormatContent
          )} `
        : `${monthYearFormatDate(new Date(formatDate))} `
    }

    return attributes.issueMonth
  } else {
    return attributes.issueYear
  }
}

export const getEducationTime = ({
  startDate,
  endDate,
  t
}: {
  startDate?: string
  endDate?: string
  t: TFunction
}) => {
  let months = 0
  let year = 0
  if (!startDate || !endDate) return ''
  if (startDate === endDate)
    return `${t('candidates:tabs:candidateOverview:education:moreThanMonth')}`

  const endDateDayjs = new Date(endDate)
  const startDateDayjs = new Date(startDate)
  months = differenceInMonths(endDateDayjs, startDateDayjs)
  year = differenceInYears(endDateDayjs, startDateDayjs)

  if (year >= 1) {
    year = Math.floor(year)
    months -= 12 * year
  }

  if (months > 0 && year < 1) {
    return `${months} ${
      months > 1
        ? `${t('candidates:tabs:candidateOverview:education:months')}`
        : `${t('candidates:tabs:candidateOverview:education:month')}`
    }`
  }

  if (months >= 0 && year == 1) {
    return `${year} ${t('candidates:tabs:candidateOverview:education:year')} ${
      months
        ? `${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  if (year > 1) {
    return `${year} ${
      year > 1
        ? `${t('candidates:tabs:candidateOverview:education:years')}`
        : `${t('candidates:tabs:candidateOverview:education:year')}`
    }${
      months > 0
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  return ''
}

export const getEducationFieldFormatDate = ({
  type = 'from',
  attributes,
  formatDate = 'MMM yyyy',
  isTemplate = false,
  keys = {},
  dateFormatContent
}: {
  type?: string
  formatDate?: string
  attributes?: EducationsType & {
    from?: {
      month?: string
      year?: string
    }
    to?: {
      month?: string
      year?: string
    }
  }
  isTemplate?: boolean
  keys?: {
    [key: string]: string
  }
  dateFormatContent?: string
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  if (type === 'from') {
    if (!attributes?.from) return keys.undefined

    if (typeof attributes?.from === 'string') {
      return isTemplate
        ? `${formatDateWithCustomSelect(
            new Date(attributes.from),
            dateFormatContent || ''
          )} `
        : `${monthYearFormatDate(new Date(attributes.from))} `
    } else {
      const formatObject = (attributes?.from || {}) as {
        month?: string
        year?: string
      }

      if (!formatObject.month && !formatObject.year) return keys.undefined

      const formatDate = `${formatObject.year}-${
        Number(formatObject.month) >= 10
          ? String(formatObject.month)
          : `0${formatObject.month}`
      }`
      if (formatObject.month) {
        if (formatObject.year) {
          return isTemplate
            ? `${formatDateWithCustomSelect(
                new Date(formatDate),
                dateFormatContent || ''
              )} `
            : `${monthYearFormatDate(new Date(formatDate))} `
        }

        return Number(formatObject.month) > 0 ? Number(formatObject.month) : ''
      } else {
        return Number(formatObject.year) > 0 ? Number(formatObject.year) : ''
      }
    }
  }

  if (type === 'to') {
    if (!attributes?.to) return keys.undefined

    if (typeof attributes?.to === 'string') {
      return isTemplate
        ? `${formatDateWithCustomSelect(
            new Date(attributes.to),
            dateFormatContent || ''
          )} `
        : `${monthYearFormatDate(new Date(attributes.to))} `
    } else {
      const formatObject = (attributes?.to || {}) as {
        month?: string
        year?: string
      }

      if (!formatObject.month && !formatObject.year) return keys.undefined

      const formatDate = `${formatObject.year}-${
        Number(formatObject.month) >= 10
          ? String(formatObject.month)
          : `0${formatObject.month}`
      }`
      if (formatObject.month) {
        if (formatObject.year) {
          return isTemplate
            ? `${formatDateWithCustomSelect(
                new Date(formatDate),
                dateFormatContent || ''
              )} `
            : `${monthYearFormatDate(new Date(formatDate))} `
        }

        return Number(formatObject.month) > 0 ? Number(formatObject.month) : ''
      } else {
        return Number(formatObject.year) > 0 ? Number(formatObject.year) : ''
      }
    }
  }

  return ''
}

export const getWorkExperienceTime = (
  item: {
    fromMonth?: number
    fromYear?: number
    toMonth?: number
    toYear?: number
    currentWorking?: boolean
  },
  t: TFunction
) => {
  let months = 0
  let year = 0
  const getFromMonth = item.fromMonth || 1
  const getToMonth = item.toMonth || 1
  const startDate = `${item.fromYear}-${
    getFromMonth >= 10 ? getFromMonth : `0${getFromMonth}`
  }`
  const endDate = `${item.toYear}-${
    getToMonth >= 10 ? getToMonth : `0${getToMonth}`
  }`
  const endDateDayjs = item.currentWorking ? new Date() : new Date(endDate)
  const startDateDayjs = new Date(startDate)
  months = differenceInMonths(endDateDayjs, startDateDayjs)
  year = differenceInYears(endDateDayjs, startDateDayjs)

  if (year >= 1) {
    year = Math.floor(year)
    months -= 12 * year
  }

  if (months > 0 && year < 1) {
    return `${t('candidates:tabs:candidateOverview:education:lessThanYear')}`
  }

  if (months >= 0 && year == 1) {
    return `${year} ${t('candidates:tabs:candidateOverview:education:year')}${
      months && item.fromMonth && item.toMonth
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }

  if (year > 1) {
    return `${year} ${
      year > 1
        ? `${t('candidates:tabs:candidateOverview:education:years')}`
        : `${t('candidates:tabs:candidateOverview:education:year')}`
    }${
      months > 0 && item.fromMonth && item.toMonth
        ? ` ${months} ${
            months > 1
              ? `${t('candidates:tabs:candidateOverview:education:months')}`
              : `${t('candidates:tabs:candidateOverview:education:month')}`
          }`
        : ''
    }`
  }
  return ''
}

export const getWorkExpFieldFormatDate = ({
  type = 'from',
  attributes = {} as any,
  isTemplate = false,
  dateFormatContent = '' as string,
  keys = {} as {
    [key: string]: string
  }
}) => {
  if (!attributes || Object.keys(attributes).length === 0) return false

  if (type === 'from') {
    if (!attributes.fromYear && !attributes.fromMonth) return keys.undefined

    const formatDate = `${attributes.fromYear}-${
      attributes.fromMonth >= 10
        ? attributes.fromMonth
        : `0${attributes.fromMonth}`
    }`
    if (attributes?.fromMonth) {
      if (attributes.fromYear) {
        return isTemplate
          ? `${formatDateWithCustomSelect(
              new Date(formatDate),
              dateFormatContent
            )} `
          : `${monthYearFormatDate(new Date(formatDate))} `
      }

      return Number(attributes.fromMonth) > 0
        ? Number(attributes.fromMonth)
        : ''
    } else {
      return Number(attributes.fromYear) > 0 ? Number(attributes.fromYear) : ''
    }
  }

  if (type === 'to') {
    if (!attributes.toYear && !attributes.toMonth) return keys.undefined

    const formatDate = `${attributes.toYear}-${
      attributes.toMonth >= 10 ? attributes.toMonth : `0${attributes.toMonth}`
    }`
    if (attributes?.toMonth) {
      if (attributes.toYear) {
        return isTemplate
          ? `${formatDateWithCustomSelect(
              new Date(formatDate),
              dateFormatContent
            )} `
          : `${monthYearFormatDate(new Date(formatDate))} `
      }

      return Number(attributes.toMonth) > 0 ? Number(attributes.toMonth) : ''
    } else {
      return Number(attributes.toYear) > 0 ? Number(attributes.toYear) : ''
    }
  }

  return ''
}

export const convertToDate = (value: any) => {
  if (value && typeof value === 'object' && 'to' in value && 'from' in value) {
    const { to, from } = value as { to: string; from: string }
    return { to: new Date(to), from: new Date(from) }
  }

  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    value instanceof Date
  ) {
    return new Date(value)
  }

  return value
}

export const generateFileName = (name: string) => {
  const today = new Date()
  return `${name
    .replace(/[^\p{L}\p{N}\s]/gu, '') // Keep letters (L), numbers (N), and spaces (\s)
    .trim()
    .replace(/\s+/g, '_')
    .toLowerCase()}_${formatDateWithKey(today, 'dd_MM_yyyy')}.csv`
}
